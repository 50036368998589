.homepage {
    overflow: hidden;
}

.video-container {
    overflow: hidden;
    position: relative;
    height: 110vh; 
    width: 100vw;
    overflow: hidden;
}

.video-container video {
    position: relative;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    /* object-fit: cover; */
    z-index: 0;
    filter: brightness(30%);
}

.content-video-container-veil {
    background: rgb(9,0,60);
    background: linear-gradient(180deg, rgba(17, 0, 110, 0.05) 0%, rgba(0,100,249,0.05) 50%, rgba(121,149,189,0.5) 90%, rgba(167,167,167,1) 100%);
    background: -moz-linear-gradient(180deg, rgba(17, 0, 110, 0.05) 0%, rgba(0,100,249,0.05) 50%, rgba(121,149,189,0.5) 90%, rgba(167,167,167,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(17, 0, 110, 0.05) 0%, rgba(0,100,249,0.05) 50%, rgba(121,149,189,0.5) 90%, rgba(167,167,167,1) 100%);
    background: linear-gradient(180deg, rgba(17, 0, 110, 0.05) 0%, rgba(0,100,249,0.05) 50%, rgba(121,149,189,0.5) 90%, rgba(167,167,167,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#09003c",endColorstr="#a7a7a7",GradientType=1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.content-video-container {
    position: absolute;
    width: 100%;
    top: 5vmax;
    right: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    /* margin-top: 7vh; */
    padding: 0vw 20vw;
}

h1.content-video-container-header {
    color: #FFF;
    font-family: 'ShineTypewriter';
    text-align: left;
    font-weight: normal;
    font-size: 8rem;
    line-height: 6.5rem;
    padding-top: 3rem;
    /* max-width: 60%; */
    height: 70vh;
    margin: 0;
}

.btn-discover {
    width: 18rem;
    height: 4rem;
    margin: 1rem 0;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #FFF !important; */
    font-family: 'BentonSans-Light';
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px !important;
    color: #FFF !important;
    background-color: #df4e00 !important;
    box-shadow: none !important;
}

.btn-discover:hover {
    color: #ffcfb9 !important;
    background-color: #753300 !important;
}

.homepage-container {
    width: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    text-align: left;
}

.services-presentation-cards, .dashboard-presentation-cards { 
    max-width: 90%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.dashboard-presentation-cards { 
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 1rem;
}

.services-presentation-cards:has(.figures-card) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    text-align: center;
}

.dashboard-presentation-cards:has(.figures-card) {
    color: #FFF !important;
}

.responsive-container-top { 
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.responsive-container {
    padding-top: 0;
    padding-bottom: 0;   
}

.responsive-container-bottom {
    padding-top: 3rem;
}

.presentation-card {
    padding: 3rem;
    border: 0;
    border-radius: 20px;
    overflow: hidden;
    text-align: left;
    background-color: #FFF;
    color: #202020;
}

.btn-as-link, .btn-as-link-themed, .btn-as-link-red, .btn-as-link-dashboard {
    color: #ff5800;
    font-family: 'BentonSans-Light';
    padding: .5rem 1rem;
    background: none;
    border: none;
    box-shadow: none;
}

.btn-as-link-red {
    color: #ff0000;
    font-size: 18px;
}

.btn-as-link-dashboard {
    margin: .5rem;
    padding: 1rem 1rem;
    color: #000;
    border-radius: 10px;
}

.btn-as-link-dashboard:hover {
    color: #FFF;
    background-color: #bd4200;
}

.btn-as-link:hover, .btn-as-link-themed:hover {
    color: #bb4100;
    border-radius: 10px;
    background: rgb(236, 236, 236);
}

.btn-as-link-themed {
    color: #2b669f;
}

.btn-as-link-themed:hover {
    color: #141466;   
}

.btn-as-link-pdf {
    color: #141466;   
    font-family: 'BentonSans-Light';
    padding: .25rem 0;
    text-decoration: underline;
    background: none;
    border: none;
    box-shadow: none;
    text-align: left;
}

.btn-as-link-pdf:hover {
    color: #ff5800;
}

.custom-presentation-card {
    padding-top: 3vh;
    background: rgb(167,167,167);
    background: -moz-linear-gradient(180deg, rgba(167,167,167,1) 0%, rgba(212,212,212,1) 50%, rgba(238,238,238,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(167,167,167,1) 0%, rgba(212,212,212,1) 50%, rgba(238,238,238,1) 100%);
    background: linear-gradient(180deg, rgba(167,167,167,1) 0%, rgba(212,212,212,1) 50%, rgba(238,238,238,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7a7a7",endColorstr="#eeeeee",GradientType=1);
}

/* .paper-texture-color, .rough-texture-color */
.paper-texture, .rought-texture, .steel-texture {
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.paper-texture { 
    background: #2b80f08e url('./../../assets/textures/paper-texture.png'); 
    background-size: cover;
    opacity: 1;
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.rough-texture { 
    background: #2222b1 url('./../../assets/textures/wall-texture.png'); 
    opacity: 1;
    border-radius: 20px;
}

.steel-texture-white { 
    background: #FFF url('./../../assets/textures/steel-texture.png'); 
    background-size: cover;
    opacity: 1;
    border-radius: 20px;
}

.steel-texture-orange { 
    background: #ff5800 url('./../../assets/textures/steel-texture-orange.png'); 
    background-size: cover;
    opacity: 1;
    border-radius: 20px;
}

.card-style-arca-plants {
    background: #FFF url('./../../assets/textures/logo-elements/arca-plants.png') no-repeat bottom right !important; 
    background-size: cover !important;
    opacity: 1;
    padding-bottom: 20vh;
    border: none !important;
}

.card-style-arca-sun {
    background: #FFF url('./../../assets/textures/logo-elements/arca-sun.png') no-repeat bottom right !important; 
    background-size: cover !important;
    opacity: 1;
    padding-bottom: 20vh;
    border: none !important;
}

.card-style-arca-waves {
    background: #FFF url('./../../assets/textures/logo-elements/arca-waves-big.png') no-repeat bottom right !important; 
    background-size: cover !important;
    opacity: 1;
    padding-bottom: 20vh;
    border: none !important;
}

/* .paper-texture-color { 
    background: rgb(40, 103, 185);
    background: -moz-linear-gradient(180deg, rgba(40,103,185,0.05) 0%, rgba(40,103,185,0.2) 100%);
    background: -webkit-linear-gradient(180deg, rgba(40,103,185,0.05) 0%, rgba(40,103,185,0.2) 100%);
    background: linear-gradient(180deg, rgba(40,103,185,0.05) 0%, rgba(40,103,185,0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2867b9",endColorstr="#2867b9",GradientType=1);
} */

/* .rough-texture-color { 
    background: rgb(34,81,154);
    background: -moz-linear-gradient(180deg, rgba(34,81,154,0.23) 0%, rgba(34,81,154,0.4) 100%);
    background: -webkit-linear-gradient(180deg, rgba(34,81,154,0.23) 0%, rgba(34,81,154,0.4) 100%);
    background: linear-gradient(180deg, rgba(34,81,154,0.23) 0%, rgba(34,81,154,0.4) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#22519a",endColorstr="#22519a",GradientType=1);
} */

.figures-card {
    padding: 2rem 3rem;
    border-radius: 20px;
    overflow: hidden;
    color: #FFF;
}

.figures-card>div>p {
    padding: 0 2rem;
    font-size: 15px;
}

.services-presentation {
    padding-top: 10vh;
    padding-bottom: 10vh;
    background: rgb(40,103,185);
    background: -moz-linear-gradient(180deg, rgba(40,103,185,0.23) 0%, rgba(40,103,185,0.4) 100%);
    background: -webkit-linear-gradient(180deg, rgba(40,103,185,0.23) 0%, rgba(40,103,185,0.4) 100%);
    background: linear-gradient(180deg, rgba(40,103,185,0.23) 0%, rgba(40,103,185,0.4) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2867b9",endColorstr="#2867b9",GradientType=1);
}

.presentation-card:has(h4) {
    background: #FFF;
    background-color: #FFF;
    color: #202020;
    /* border: 1px solid #2867b9; */
}

.presentation-card:has(p) {
    line-height: 4vh;
}

.parteners-card {
    display: flex;
    justify-content: center;
}

.prompting-card {
    color: #202020;
    /* background-color: #fc8525; */
    border: 0;
    /* border: 0.15rem solid #cf3700; */
}

.input-mailing-list {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 50px !important;
    color: #202020 !important;
    border: 1px solid transparent !important;
    border-radius: 15px !important;
}

.btn-join-mailing-list {
    background-color: #FFF !important;
    width: 100%;
    height: 55px;
}

.btn-join-mailing-list:hover {
    color: #FFF !important;
    background-color: #bb4100 !important;
}

@media only screen and (max-width: 540px) {
    .responsive-container-top { 
        padding: 15px 15px 0;
        margin-right: auto;
        margin-left: auto;
    }

    .responsive-container {
        padding: 0 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .responsive-container-bottom {
        padding-top: 0;
    }

    .services-presentation-cards, .dashboard-presentation-cards {
        max-width: 100%;
        gap: 1rem;
        overflow: visible;
        display: block;
    }

    .presentation-card:has(h4) {
        margin: 3rem 0;
    }

    .figures-card {
        margin: 2rem 0;
        padding: 2rem;
    }

    .parteners-cards {
        max-width: 90%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        align-self: center;
        gap: 1rem;
        /* grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); */
    }
}

@media only screen and (min-width: 576px) {
    .services-presentation-cards, .dashboard-presentation-cards {
        max-width: 540px;
    }
}

@media only screen and (min-width: 768px) {
    .services-presentation-cards, .dashboard-presentation-cards {
        max-width: 720px;
    }
}

@media only screen and (min-width: 992px) {
    .services-presentation-cards, .dashboard-presentation-cards {
        max-width: 960px;
    }
}

@media only screen and (min-width: 1200px) {
    .services-presentation-cards, .dashboard-presentation-cards {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .services-presentation-cards, .dashboard-presentation-cards {
      max-width: 1320px;
    }
}

@media only screen and (max-width: 400px) {
    h1.content-video-container-header {
        font-size: 3rem !important;
        line-height: 3rem !important;
        padding-top: 3rem;
        height: 50vh;
        margin: 0;
    }
    
    .btn-discover {
        width: 15rem;
        height: 3rem;
        font-size: 15px;
        padding: 10px;
        margin: 10px;
    }
}

@media only screen and (max-width: 576px) {
    h1.content-video-container-header {
        font-size: 3.5rem;
        line-height: 3.5rem;
        padding-top: 3rem;
        height: 45vh;
        margin: 0;
    }
    
    .btn-discover {
        width: 15rem;
        font-size: 15px;
        padding: 10px;
        margin: 10px;
    }
}

/* @media only screen and (min-width: 576px) and (max-width: 768px) {
    h1.content-video-container-header {
        font-size: 4rem;
        line-height: 4rem;
        padding-top: 2rem;
        height: 50vh;
        margin: 0;
    }

    .btn-discover {
        font-size: 15px;
        height: 3rem;
        width: 15rem;
        padding: .5rem !important;
        margin: .5rem 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    h1.content-video-container-header {
        font-size: 5rem;
        line-height: 5rem;
        padding-top: 1rem;
        height: 50vh;
        margin: 0;
    }

    .btn-discover {
        font-size: 20px;
        height: 5rem;
        width: 20rem;
        padding: .5rem !important;
        margin: .5rem 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    h1.content-video-container-header {
        font-size: 5rem;
        line-height: 5rem;
        padding-top: 1rem;
        height: 50vh;
        margin: 0;
    }

    .btn-discover {
        font-size: 20px;
        height: 5rem;
        width: 20rem;
        padding: .5rem !important;
        margin: .5rem 0;
    }
} */

@media only screen and (orientation: portrait) and (max-width: 1023px) {
    .content-video-container {
        top: 15vmax;
    }

    .video-container {
        /* height: 95vmax;  */
        width: 100%;
    }

    .content-video-container {
        padding: 0vw 10vw;
    }

    /* .homepage-container {
        padding: 5vh 0;
    } */

    .responsive-container-top { 
        /* padding: 15px 15px 0; */
        margin-right: auto;
        margin-left: auto;
    }

    .responsive-container {
        padding: 0 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .responsive-container-bottom {
        padding-top: 0;
    }

    .collapsable-row {
        flex-direction: column;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1023px) {
    .content-video-container {
        top: 5vw;
    }

    /* h1.content-video-container-header {
        font-size: 3rem;
        line-height: 3rem;
        height: 75vh;
    }

    .collapsable-row {
        flex-direction: row;
    }

    .btn-discover {
        font-size: 2vw;
        width: 60vh;
        height: 7vh;
        padding: 1.5rem !important;
    } */

    /* .homepage-container {
        padding: 5vh 0;
    } */

    .responsive-container-top { 
        /* padding: 15px 15px 0; */
        margin-right: auto;
        margin-left: auto;
    }

    .responsive-container {
        padding: 0 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .reponsive-container-bottom {
        padding-top: 0;
    }
}
