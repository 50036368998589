.aws-btn {
	--button-default-height: 47px;
	--button-default-font-size: 16px;
	--button-default-border-radius: 15px;
	--button-horizontal-padding: 20px;
	--button-raise-level: 5px;
	--button-hover-pressure: 2;
	--transform-speed: 0.185s;
	--button-primary-color: #b5df81 !important;
	--button-primary-color-dark: #43862d;
	--button-primary-color-light: #3f8228;
	--button-primary-color-hover: #83cc61;
	--button-primary-border: 0px solid #ffffff;
	--button-secondary-color: #f2c7a6;
	--button-secondary-color-dark: #c76d29;
	--button-secondary-color-light: #a86629;
	--button-secondary-color-hover: #d2a05b;
	--button-secondary-border: 0px solid #FFFFFF;
	--button-anchor-color: #d6aeae;
	--button-anchor-color-dark: #ac1616;
	--button-anchor-color-light: #983434;
	--button-anchor-color-hover: #ee9090;
	--button-anchor-border: 0px solid #ffffff;
	--button-disabled-color-light: #646464;
}
