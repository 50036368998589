.footer {
    background-color: #191970;
    color: #FFF;
    left: 0;
    bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    text-align: center;
    padding: 1vh 20vw 1vh 20vw;
}

.footer-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: left;
    text-align: left;
    margin: 20px 2vw;
}

.footer-container a {
    color: #ff5800 !important;
    font-family: 'BentonSans-Light';
    font-size: 14px;
    padding: 10px 0;
    text-decoration: none;
}

.footer-container a:not(:has(img)) {
    padding: 15px;
}

.footer-container a:hover:not(:has(img)) {
    color: #c0dbff;
    /* color: #bb4100; */
    background-color: none;
    border-radius: 10px;
    background: #14145a;
}

.footer-container p {
    font-family: 'BentonSans-Light';
    font-size: 14px;
    padding: 0 15px;
}

.footer-hr {
    border-top: 1px solid #FFF;
}

.footer-title {
    text-align: left;
    font-size: 2.4vh;
    padding: 15px;
}

.footer-img {
    max-width: 50px;
    margin: 10px;
}

.footer-table, .footer-table.tbody {
    padding: 0 15px;
    display: flex;
    justify-content: center;
}

.footer-table td:nth-child(2) {

}

.footer-table td>p {
    padding-top: 0px;
}

.footer-signature { 
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    background-color: #191970;
    margin: 0;
    color: #FFF;
    padding: 7vh 20px 20px 20px;
}

@media only screen and (max-width: 1025px) {

    .footer {
        flex-direction: column;
        padding: 0.7vh 5vw 0.7vh 5vw;
    }

    .footer-container {
        text-align: left;
        margin: 20px 0; 
    }

    .footer-title {
        text-align: left;
    }

    .footer-img {
        max-width: 50px;
        margin: 10px;
    }

    .footer-table {
        display: flex;
        justify-content: left;   
    }

    .footer-signature {
        padding: 20px 15px;
        align-items: flex-start;
        text-align: left;
    }
}