.pdf-viewer-modal {
    max-width: 100% !important;
    padding: 2rem 5rem !important;
    background-color: #20202094 !important;
}

.pdf-viewer-modal > .modal-content {
    min-height: 49rem !important;
    /* background-color: #f3eeee !important; */
}

.rpv-default-layout__container, .rpv-default-layout__main, .rpv-default-layout__sidebar.rpv-default-layout__sidebar--ltr, .rpv-default-layout__sidebar-tabs, .rpv-default-layout__sidebar-headers {
    border-bottom-left-radius: 15px !important;
}

.rpv-default-layout__container, .rpv-default-layout__main, .rpv-default-layout__body, .rpv-core__inner-pages.rpv-core__inner-pages--vertical {
    border-bottom-right-radius: 15px !important;
}
 
.pdf-viewer-content {
    height: 42rem !important;
}

/* Remove Full Screen control in PDF viewer */
[aria-describedby="rpv-core__tooltip-body-full-screen-enter"], [aria-describedby="rpv-core__tooltip-body-open"] {
    display: none !important;
}

[aria-describedby="rpv-core__tooltip-body-get-file"] {
    padding-right: 0.7vw;
}

[aria-describedby="rpv-core__tooltip-body-print"] {
    padding-right: 0.3vw;
}

.modal-backdrop.show {
    opacity: .8 !important;
}

@media (max-width: 1200px) {
    .modal-body {
        padding: 0.2rem !important;
    }
    
    .pdf-viewer-modal {
        padding: 1rem !important;
    }
}
