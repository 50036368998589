.web {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2rem calc((5vw - 50px) / 2); 
    z-index: 12; 
}

.navbar-toggler {
    display: none;
}

nav {
    justify-content: flex-end;
    padding: 2rem 0;
}

.navbar-links {
    justify-content: right;
}

.navbar-link {
    color: #FFF !important;
    border-radius: 7px;
    text-decoration: none; 
    padding: 0 .75rem;
    margin-right: 0.7vh;
    letter-spacing: .05rem; 
    font-size: 18px;
    cursor: pointer; 
    font-weight: bold;
    font-family: 'Canela-Thin';
}

.navbar-link:hover {
    color: #ff5800;
}

.navbar-link-active {
    padding: 0 .75rem;
    margin-right: 0.7vh;
    text-decoration: underline;
    text-underline-offset: .5rem;
    text-decoration-thickness: 1.5px;
    color: #ff5800 !important;
}

.option-icon {
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.web-option:last-child {
    margin-right: 0px;
}
