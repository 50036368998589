body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "MontLight";
  src: local("MontLight"),
   url("./assets/fonts/mont/Mont-ExtraLightDEMO.otf") format("truetype");
}

@font-face {
  font-family: "MontBold";
  src: local("MontBold"),
   url("./assets/fonts/mont/Mont-HeavyDEMO.otf") format("truetype");
}

@font-face {
  font-family: "KeepCalm";
  src: local("KeepCalm"),
   url("./assets/fonts/keep_calm/KeepCalm-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "NewWake";
  src: local("NewWake"),
   url("./assets/fonts/newake/NewakeFont-Demo.otf") format("truetype");
}

@font-face {
  font-family: "Canela-Deck";
  src: local("Canela-Deck"),
    url("./assets/fonts/Canela_Collection/Canela_Deck_Family/CanelaDeck-Medium-Trial.otf") format("truetype");
}

@font-face {
  font-family: "Canela-Thin";
  src: local("BentonSans-Thin"),
    url("./assets/fonts/Canela_Collection/Canela_Deck_Family/CanelaDeck-Thin-Trial.otf") format("truetype");
}

@font-face {
  font-family: "BentonSans-Light";
  src: local("BentonSans-Light"),
    url("./assets/fonts/bentonsansregular/BentonSans\ Regular.otf") format("truetype");
}

@font-face {
  font-family:"ShineTypewriter";
  src: local("ShineTypewriter"),
    url("./assets/fonts/shine_typewriter/ShineTypewriter.ttf") format("truetype");
}

.loading-page {
  background: linear-gradient(180deg, #1C2269 50%, #18144F 100%) !important;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
}

.loading-prompt {
  /* font-family: "Antihero"; */
  /* color: orangered; */
  
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: auto;
  padding: auto;
}