.bullet-li {
    list-style-type: disc;
    display: block;
    padding: .1rem;
    padding-left: 1.5rem;
    align-items: left;
    justify-content: left;
}

.bullet-li#small-li {
    padding-top: 0;
    padding-bottom: 0;
}

ol {
    display: block;
    padding: 0 1.5rem 1.5rem 1rem;
    align-items: left;
    justify-content: left;
}

ol>li {
    padding: 1rem 0;
    padding-left: 1rem;
}

li {
    border-radius: 20px;
    padding-left: 1rem;
}

li::marker {
    margin: 1rem;
}

.about-us-description {
    padding-bottom: 3rem;
}
