
.card-style-steel-texture-blue {
    /* border: 1px solid #0951af; */
    background: #FFF url('./../../assets/textures/steel-texture.png') !important; 
    opacity: 1;
    color: #202020;
    text-align: left;
}

.card-style-steel-texture-orange {
    /* border: 1px solid #ff5800; */
    background: #FFF url('./../../assets/textures/steel-texture.png'); 
    opacity: 1;
    color: #202020;
    text-align: left;
    font-weight: bold;
}