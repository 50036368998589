#typewriter {
    font-size: 2em;
    margin: 0;
    /* font-family: "Courier New", Courier, monospace; */
    white-space: pre-wrap;
    word-wrap: break-word;
    display: inline-block;
    /* position: relative; */
}

.cursor-visible {
    opacity: 1;
    color: #ff5800;
    animation: blink 500ms linear infinite alternate;
}

.cursor-hidden {
    opacity: 0;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
