.custom-invisible-btn {
    background-color: transparent;
    border-radius: 0;
    border-color: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
}

.custom-invisible-btn:hover {
    background-color: transparent;
    border-color: transparent;
}

.image-circular-interactive-frame {
    display: inline-block; /* Here !!! */
    position: relative;
    margin: 0;
    cursor: pointer;
    height: inherit;
}

.image-circular-interactive-frame img { 
    max-width: 100%; 
}

figcaption {
    position: absolute;
    /* Circular border */
    border-top-left-radius: 50% 50%; 
    border-top-right-radius: 50% 50%; 
    border-bottom-right-radius: 50% 50%; 
    border-bottom-left-radius: 50% 50%; 
    display: none;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    font-size: 1.5vmax;
}

/* figure:hover */
.image-circular-interactive-frame:hover figcaption {
    display: block;
    background-color: rgba(33, 33, 33, 0.8);
    width: 100%;
    height: auto;
    /* max-width: 200px; */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview {
    border: solid 1px #FFF;
}

.advanced-cropper {
    background-color: #FFF !important;
}

.advanced-cropper-boundary__stretcher {
    max-width: 20rem !important;
    max-height: 20rem !important;
}
