@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: #fcfcfc;
    background-color: #fcfcfc;
    letter-spacing: 0 !important;
    color: #202020;
    font-family: 'BentonSans-Light', sans-serif !important;
}

/* 
.MuiAppBar-colorPrimary {
  background-color: rgb(255, 155, 0) !important;
} */

/* Code Selection color setup */
::-moz-selection { /* Code for Firefox */
    color: #2b669f;
    background: #e9f0f7;
}

::selection {
    /* color: black;
    background: #ffcd00; */
    color: #2b669f;
    background: #e9f0f7;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, 1);
}

.slim-hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

nav {
    background-color: #141466 !important;
    padding: 0px !important;
}

nav#dim-styling-nav {
    background-color: #000 !important;
}

.MuiAppBar-colorPrimary {
    background-color: #141466 !important;
}

.MuiAppBar-colorPrimary:has(#dim-styling) {
    background-color: #000 !important;
}

/* .navbar {
    padding: 0 5vh !important;
}

.navbar-collapse {
    width: 100% !important;
    justify-content: right !important;
}

.navbar-toggler {
    border: none;
    color: #FFF;
    font-size: 30px;
    border: none;
    border-radius: 0px;
}

.navbar-toggler:focus {
    border: none;
    border-radius: 0px solid transparent;
    box-shadow: 0 0 0 0;
} */

.MuiBox-root-2 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

@media (min-width: 600px) {
    .MuiToolbar-regular {
        min-height: 48px !important;
    }
}

.content {
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 70vh;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* padding: 8vh 0 8vh 0; */
    margin: 12vh 10vw 13vh 10vw;
}

.content:has(.homepage) {
    margin: 0px;
    top: 0;
    position: relative;
}

.important-text {
    color: #ff5800;
}

.important-title { 
    color: #00009c;
}

.container-sub-title {
    text-align: left;
    padding-left: 4rem;
    padding-bottom: 2rem;
}

.big {
    font-size: 3.5vmax;
    font-family: 'Canela-Deck';
    padding-bottom: 0;
    font-weight: bold;
    color: #FFF;
}

.MuiAppBar-root {
    z-index: 12 !important;
}

.MuiBox-root-6, .MuiAppBar-root.jss1 {
    margin: 0 !important;
}

.center-to-screen {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-navbar {
    margin-left: 0 !important;
}

a {
    color: #141466 !important;
}

a:hover {
    color: #ff5800 !important;
}
/* 
a:visited {
    color: #FFF;
} */

ul {
    list-style: none;
    text-decoration: none;
}

li {
    font-size: 1.2rem;
}

h1 {
    color: #000;
    font-family: 'Canela-Deck';
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
    word-spacing: 0.5rem;
}

h2 {
    font-family: 'Canela-Deck';
    font-weight: bold !important;
    font-size: 2rem;
}

h3 {
    font-family: 'Canela-Deck';
    font-weight: bold;
}

h4 {
    font-family: 'Canela-Deck';
    font-weight: bold;
} 

p {
    font-family: 'BentonSans-Light';
    font-size: 15px;
    margin: 0;
}

#arca-logo:hover {
    content: url('./../images/logo/logos_minimal.png');
}

.scroll-to-top-helper-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 9;
    font-size: 15px;
    border: none;
    outline: none;
    color: #2b669f;
    background: #e9f0f7;
    cursor: pointer;
    padding: 15px;
    border-radius: 50%;
}

.scroll-to-top-helper-button:hover {
    background-color: #d7eaff;
}

.card-shadow {
    /* box-shadow: 10px 10px 50px rgba(0, 41, 64, 0.20) !important; */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25) !important;
}

.custom-card {
    padding: 2rem;
    width: 60%;
    border-radius: 20px;
    margin: 50px auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    text-align: left;
    border: none;
}

.custom-card-mailinglist {
    width: 60%;
    border-radius: 20px;
    margin: 20px auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    text-align: left;
    border: none;
}

.contact-us-card {
    padding: 2rem;
    width: 50%;
    border-radius: 20px;
    margin: 10px auto 50px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    border: none;
}

.card-publication {
    width: 90%;
}

.jumbotron-wrapper {
    padding: 2rem;
    border-radius: 20px;
    margin: 50px auto;
    text-align: left;
    background-color: #e7e7e7;
}

.card-public {
    width: 50%;
}

.card-public:nth-child(3n+3) {
    background: #FFF url('./../../assets/textures/logo-elements/arca-plants-small.png') no-repeat bottom right !important; 
    background-size: cover !important;
    opacity: 1;
    border: none !important;
}

.card-public:nth-child(3n+1) {
    background: #FFF url('./../../assets/textures/logo-elements/arca-sun-small.png') no-repeat bottom right !important; 
    background-size: cover !important;
    opacity: 1;
    border: none !important;
}

.card-public:nth-child(3n+2) {
    background: #FFF url('./../../assets/textures/logo-elements/arca-waves-small.png') no-repeat bottom right !important; 
    background-size: cover !important;
    opacity: 1;
    border: none !important;
}

.card-style-steel-texture {
    background: #FFF url('./../../assets/textures/steel-texture.png') no-repeat !important; 
    background-size: cover !important;
    opacity: 1;
}

.ul.nav {
    margin: auto 50px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    text-align: center;
}

ul.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    margin: 0px auto 50px;
    display: flex;
    justify-content: left;
    /* padding-top: 2rem; */
}

.nav-tabs .nav-link {
    font-size: 14px;
    color: #797979;
    border-radius: 10px 10px 0 0 !important;
}

li.nav-item {
    padding-left: 0;
}

.nav-tabs .nav-link:hover {
    color: #202020;
}

.nav-tabs .nav-link.active {
    color: #2b669f !important;
    background-color: #e9f0f7 !important;
    border-color: #dee2e6 #dee2e6 #fff;
}

.card-modal {
    border-radius: 15px;
}

.custom-card.viewer:first-of-type {
    margin-top: 0px;
}

.image-container {
    flex: 1;
    padding: 20px;
    min-width: 100px;
}

.image {
    width: 100%;
    height: auto;
    max-width: 120px;
    display: block;
}

.circular {
    border-top-left-radius: 50% 50%; 
    border-top-right-radius: 50% 50%; 
    border-bottom-right-radius: 50% 50%; 
    border-bottom-left-radius: 50% 50%; 
}

.text-container, .text-container-mailinglist {
    flex: 3;
    padding: 20px;
    box-sizing: border-box;
}

.text-container-mailinglist {
    align-content: center;
    overflow-wrap: break-word;
    max-width: 70%;
}

.buttons-container, .buttons-container-mailinglist {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    padding: 20px;
    box-sizing: border-box;
}

.buttons-container-mailinglist {
    padding: 20px;
    flex-direction: row;
    justify-content: right;
    max-height: 90px;
}

.custom-modal {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    text-align: left;
}

.modal-container {
    flex: 1;
    padding-left: .75rem;
}

.modal-container-xlg {
    flex: 2;
    padding-right: .75rem;
}

.modal-content {
    border: 1px solid #414141;
    box-shadow: 0px 3px 1px #3b3b3b;
    background-color: #f3eeee;
    border-radius: 15px !important;
}

label {
    margin-bottom: 0px;
}

.form-control, .form-select {
    border: 1px solid #717171 !important;
    margin: 5px !important;
    border-radius: 10px !important;
}

label>#floatingInput {
    color: rgba(33, 37, 41, .65) !important;
}

input[type="file"] {
    display: none;
}

.form-select {
    padding: 6px 12px;
    background-position: right 1.25rem center;
    width: 100%;
    height: calc(1.5em + .75rem + 5px);
    background-color: #FFF;
    appearance: none;
    background-position: right .75rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
}

.form-control[type=file] {
    padding: 3px;
}

.btn {
    border: 1px solid #414141 !important;
    box-shadow: 0px 4px 0px #3b3b3b;
    margin: 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
}

input.PhoneInputInput {
    border: 0px solid transparent;
    padding-top: .5rem;
}

input.PhoneInputInput:focus {
    border: none;
    outline: none;
}

.btn-contact-us {
    background-color: #ff5800 !important;
    color: #FFF !important;
    width: 50%;
    height: 55px;   
}

.btn-contact-us:hover {
    background-color: #bd4200 !important;
    color: #FFF !important;
}

.btn-bottom {
    position: absolute;
    bottom: 0;
}

.btn-primary {
    background-color: #000 !important;
    border-radius: 15px !important;

    &:hover {
        background-color: #ff5800 !important;
    }
}

.btn-secondary {
    border-radius: 15px !important;
}

.btn-outline-primary {
    color: #000 !important;
    border-radius: 15px !important;
}

.btn-outline-primary:after {
    background-color: #000 !important;
}

.btn-outline-primary:hover {
    border-color: #0a193d !important;
    background-color: #000 !important;
    color: #FFF !important;
    border-radius: 15px !important;

    ::before {
        background-color: #0a193d !important;
    }
}

.btn-warning,
.btn-outline-warning:hover {
    background-color: #ff5800 !important;
    border-radius: 15px !important;
}

.btn-warning:hover {
    color: #FFF !important;
    background-color: #ff5800 !important;
}

.btn-outline-warning {
    color: #ff5800 !important;
    border-radius: 15px !important;
}

.btn-outline-warning:hover {
    color: #000 !important;
}

.btn-danger, .btn-outline-danger {
    border-radius: 15px !important;
}

.btn-danger,
.btn-outline-danger:hover {
    background-color: #c1121f !important;
    border-radius: 15px !important;
}

.btn-danger:hover {
    background-color: #a4161a !important;
}

.btn-outline-primary:disabled {
    color: #999999 !important;
    border-color: #999999 !important;
    border-radius: 15px !important;
    box-shadow: 0px 4px 0px #999999;
}

.btn-outline-primary:disabled:hover {
    background-color: transparent !important;
}

.btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E);
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    box-sizing: initial;
    height: 1em;
    opacity: var(--bs-btn-close-opacity);
    padding: 0.25em;
    width: 1em;
}

.MuiSnackbar-root, .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
    border-radius: 10px;
    top: 80px !important;
    height: 50px;
    right: 30px !important;
    color: #202020 !important;
}

.css-1pxa9xg-MuiAlert-message {
    padding: 10px 0 !important;
}

@media (max-width: 1025px) {
    .navbar {
        padding: 0 15px !important;
    }

    .content {
        margin: 13vh 1vw 5vh 1vw;
    }

    .container-sub-title {
        padding-left: 2rem;
        padding-bottom: 0;
    }

    .big {
        font-size: 5vmax;
    }

    .custom-card {
        width: 90%;
        padding: 0.5rem;
        flex-direction: column;
    }

    .custom-card-mailinglist {
        width: 90%;
        padding: 0.5rem;
    }

    .text-container-mailinglist {
        padding: 5px;
    }

    .contact-us-card {
        width: 90%;
        
    }

    .PhoneInput {
        margin-bottom: .75rem;
    }

    .card-public {
        width: 90%;
    }

    ul.nav-tabs {
        border-bottom: 1px solid #dee2e6;
        margin: 0px auto 50px;
        width: 90%;
        display: flex;
        justify-content: left;
    }    

    .image-container {
        /* Move image container to the top in mobile view */
        order: -1;
        margin: auto;
    }

    .text-container,
    .buttons-container {
        width: 100%;
    }

    .buttons-container-mailinglist {
        padding: 0;
        flex-direction: column;
        justify-content: center;
    }    

    .custom-modal {
        flex-direction: column;
    }

    .btn-contact-us {
        width: 100%;
    }
}