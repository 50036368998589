.mobile {
    background-color: #141466;
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    padding: 18px 12px;
    display: flex;
    flex-direction: column;
    z-index: 12;
    /* align-items: center; */
    /* overflow: hidden; */
}

.mobile#dim-styling-mobile-nav {
    background-color: #000 !important;
}

.close-icon {
    font-size: 30px;
    color: #FFF;
    padding: 0 2vh;
    text-align: right;
}

.mobile-options {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 24px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mobile-option {
    font-size: 24px;
    margin: 12px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.mobile-option a {
    text-decoration: none;
    padding: 1vh 0;
    color: #FFF !important;
}

.mobile-option:has(.btn-as-link-red) {
    padding: 15vh 0 0;
    /* align-items: center; */
    /* justify-content: space-between; */
}

.mobile-option>.active {
    text-decoration: underline;
    text-underline-offset: .5rem;
    text-decoration-thickness: 1.5px;
    color: #ff5800 !important;
}