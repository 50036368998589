.header {
    display: flex;
    justify-content: space-between;
    flex: 1;
    z-index: 12;
    padding: 0;
    margin: 0;
}

.menu {
    display: flex;
    flex: 1;
    padding: .5rem 3rem;

    color: #FFF; 
    border-radius: 7px;
    align-items: left; 
    text-decoration: none; 
    margin-right: 0.7vh;
    letter-spacing: .05rem; 
    font-size: 18px;
    font-weight: bold;
    font-family: 'Canela-Thin';
    &:hover {
        color: #ff5800;
    }
    &.active { 
        text-decoration: underline;
        text-underline-offset: .5rem;
        text-decoration-thickness: 1.5px;
        color: #ff5800 !important;
    } 
}

.navbar {
    /* padding: 0 5vh !important; */
    width: 100%;
}

.navbar-collapse {
    width: 100% !important;
    justify-content: right !important;
}

.navbar-toggler {
    border: none;
    color: #FFF;
    font-size: 30px;
    border: none;
    border-radius: 0px;
}

.navbar-toggler:focus {
    border: none;
    border-radius: 0px solid transparent;
    box-shadow: 0 0 0 0;
}

.MuiAppBar-root {
    flex-direction: unset !important;
}

.MuiToolbar-regular {
    min-height: 48px !important;
}

.menu-icon {
    font-size: 30px;
    text-align: left;
}

.mobile-menu {
    display: none;
}

.web-menu {
    display: contents;
}

.logo {
    display: flex;
    font-size: 36px;
    color: blue;
    font-weight: 800;
}

@media only screen and (max-width: 1024px) {
    .menu {
        padding: 1rem .5rem .5rem;
        margin: 0;
        display: inline-block;
    }

    .mobile-menu {
        display: flex;
        justify-content: space-between;
    }

    .web-menu {
        display: none;
    }
    
    .logo {
        text-align: left;
        display: table-cell;
        font-size: 24px;
    }

    .menu-icon {
        font-size: 30px;
        text-align: right;
        display: table-cell;
    }
}